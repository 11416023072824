import React, { ChangeEvent, useState } from 'react';

import VectordbAkamaiPlans from '../contents/plans/vectordb/akamai.json';
import VectordbHetznerPlans from '../contents/plans/vectordb/hetzner.json';
import AkamaiLogo from '../icons/akamai.svg';
import HetznerLogo from '../icons/hetzner.svg';
import KafkaLogo from '../icons/services/kafka.svg';
import VectorDBLogo from '../icons/services/vectordb.svg';
import Layout from '../components/Layout';

import TableView from '../components/TableView';
import CTA from '../components/CTA';
import { useSiteMetadata } from '../hooks';
import Container from '../components/Container';
import { HeadFC } from 'gatsby';
import Seo from '../components/Seo';
import KafkaPlan from '../components/plans/KafkaPlan';
import VectorDBPlan from '../components/plans/VectordbPlan';

interface Plan {
  name: string;
  description: string;
  plans: { [key: string]: string }[];
}

const PROVIDERS = [
  {
    label: 'Hetzner',
    key: 'hetzner',
    icon: <HetznerLogo className="md:h-8 md:w-8 h-5 w-5" />,
  },
  {
    label: 'Akamai Cloud',
    key: 'akamai',
    icon: <AkamaiLogo className="md:h-8 md:w-8 h-5 w-5" />,
  },
];

const SERVICES = [
  {
    label: 'Kafka',
    key: 'kafka',
    icon: <KafkaLogo className="md:h-8 md:w-8 h-5 w-5" />,
  },
  {
    label: 'VectorDB',
    key: 'vectordb',
    icon: <VectorDBLogo className="md:h-8 md:w-8 h-5 w-5" />,
  },
];

const Pricing = () => {
  const data = useSiteMetadata();
  const [selectedService, setSelectedService] = useState(SERVICES[0].key);
  const [selectedProvider, setSelectedProvider] = useState(PROVIDERS[0].key);

  const handleRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedProvider(e.target.value);
  };

  const handleService = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(e.target.value);
  };

  const getPlansComponent = () => {
    switch (selectedService) {
      case 'kafka':
        return <KafkaPlan provider={selectedProvider} />;
      case 'vectordb':
        return <VectorDBPlan provider={selectedProvider} />;
    }
  };

  return (
    <Layout>
      <section className="my-6 section">
        <Container>
          <div className="space-y-6">
            <h1 className="text-center">Bitnimbus.io Platform Pricing</h1>
            <h3 className="tagline text-center">
              The most affordable enterprise-grade data streaming platform
            </h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CTA
                type="primary"
                link="/#contact-us"
                external={false}
                label="Get in Touch"
              />
            </div>
          </div>
          <div className="mt-16">
            <h2 className="text-center font-bold">
              Find the Right Plan for You
            </h2>
            <div className="space-y-12">
              <div>
                <h3 className="text-foreground text-2xl">Product</h3>
                <div className="flex items-center mt-4 space-x-6">
                  {SERVICES.map((service) => (
                    <div
                      className={`flex items-center ${selectedService === service.key ? 'text-foreground' : ''}`}
                    >
                      <input
                        type="radio"
                        id={service.key}
                        aria-checked={selectedService === service.key}
                        name={service.key}
                        value={service.key}
                        checked={selectedService === service.key}
                        className={
                          selectedService === service.key
                            ? 'opacity-100'
                            : 'opacity-70'
                        }
                        onChange={handleService}
                      />
                      <label
                        htmlFor={service.key}
                        className="flex items-center cursor-pointer"
                      >
                        <span className="only-of-type:mx-2">
                          {service.icon}
                        </span>
                        <p className="md:text-[1.25rem]">{service.label}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-foreground text-2xl">Cloud Provider</h3>
                <div className="flex items-center mt-4 space-x-6">
                  {PROVIDERS.map((provider) => (
                    <div
                      className={`flex items-center ${selectedProvider === provider.key ? 'text-foreground' : ''}`}
                    >
                      <input
                        type="radio"
                        id={provider.key}
                        aria-checked={selectedProvider === provider.key}
                        name={provider.key}
                        value={provider.key}
                        checked={selectedProvider === provider.key}
                        className={
                          selectedProvider === provider.key
                            ? 'opacity-100'
                            : 'opacity-70'
                        }
                        onChange={handleRadio}
                      />
                      <label
                        htmlFor={provider.key}
                        className="flex items-center cursor-pointer"
                      >
                        <span className="only-of-type:mx-2">
                          {provider.icon}
                        </span>
                        <p className="md:text-[1.25rem]">{provider.label}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="space-y-16">
                {getPlansComponent()}
                <div className="flex flex-col items-center justify-center space-y-4">
                  <h2 className="text-foreground">
                    {selectedService === 'kafka'
                      ? 'Spin Up Kafka Instances In Minutes'
                      : 'Spin Up VectorDB Instance In Minutes'}
                  </h2>
                  <CTA
                    label="Start Your Free Trial"
                    external={true}
                    link={data.site.siteMetadata.productLink}
                    type="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <>
    <Seo
      title="Pricing - Bitnimbus.io"
      canonical="https://bitnimbus.io/pricing"
      description="Discover affordable, fully managed Kafka clusters that fit your needs! Starting at $16/month with no credit card required. Sign up now and optimize your workflow!"
    />
  </>
);

export default Pricing;
